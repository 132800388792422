import React from "react";
import styled from "styled-components";
import { prop } from "ramda";
import { propTernary } from "../../utils/styled";

const Item = styled.div`
  flex: ${({ $autoSize }) => $autoSize ? 'auto' : '0px'} ${({ $keepColumnSize }) => ($keepColumnSize ? 0 : 1)} 0;
  padding: ${({ $noPadding }) => ($noPadding ? 0 : "30px")};
  @media screen and (max-width: 767px) {
    padding: ${({ $noPadding }) => ($noPadding ? 0 : "15px")};
  }
`;

const Root = styled.section`
  position: relative;
  display: flex;
  align-items: ${prop("$verticalAlignment")};
  justify-content: ${prop("$horizontalAlignment")};
  ${propTernary("$wrap", "flex-wrap: wrap;", "")}
  overflow: hidden;
  width: 100%;
  @media screen and (max-width: ${prop("$mobileBreakpoint")}px) {
    flex-wrap: ${propTernary("$reverseOnMobile", "wrap-reverse", "wrap")};
    & > ${/* sc-sel */ Item} {
      flex: 100% 0 0;
    }
  }
`;

const Columns = (props) => {
  return (
    <Root
      $wrap={props.$wrap}
      $mobileBreakpoint={props.$mobileBreakpoint}
      $reverseOnMobile={props.$reverseOnMobile}
      $horizontalAlignment={props.$horizontalAlignment || "center"}
      $verticalAlignment={props.$verticalAlignment || "center"}
    >
      {React.Children.map(props.children, (child) => (
        <Item
          $autoSize={props.$autoSize}
          $keepColumnSize={props.$keepColumnSize}
          $noPadding={props.$noCellPadding}
        >
          {child}
        </Item>
      ))}
    </Root>
  );
};

export default Columns;
