import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslations } from '../../../shared/context/translations';
import { BUTTON_SIZES, BUTTON_VARIANTS, Button } from '../../../shared/ui/button';
import { colors } from '../../../shared/ui/colors';
import parseMarkdown from '../../../shared/utils/markdown';
import SimplifiedFooter from '../../partials/footer/simplified';
import NoContextHeader from '../../partials/header/no-context-header'
import { Description, Fold, Image, ImageWrapper, Mosaic, Root, TextHolder, Tile, Title } from './styled';
import { HeaderText } from '../../partials/header/styled';
import { H6 } from '../../../shared/ui/headings';

const desc = 'Sustainable acoustic booths for Hospitality, Offices and Professional Audio Environments developed for sound insulation, privacy and sound performance.'

const DisambiguationHome = props => {
  const t = useTranslations('disambiguationHome')

  return (
    <Root>
      <Helmet>
        <title>VicBooth - Acoustic and Soundproof Booths</title>
        <meta name="description" content={desc} />
        <meta property="og:image" content="/images/vicbooth.jpg" />
        <meta property="og:title" content={`VicBooth - Acoustic and Soundproof Booths`} />
        <meta property="og:description" content={desc} />
        <meta property="og:site_name" content="Vicbooth" />
      </Helmet>
      <Fold>
        <NoContextHeader>
          <HeaderText>
            <H6 dangerouslySetInnerHTML={{ __html: parseMarkdown(t('newsBanner.title'))}} />
          </HeaderText>
        </NoContextHeader>
        <Mosaic>
          <Tile $bg={t('office.backgroundImage.src')} $color={colors.secondary.office} to={t('office.button.link')}>
            <TextHolder>
              <Title dangerouslySetInnerHTML={{__html: parseMarkdown(t('office.title'))}} />
              <Description>{t('office.description')}</Description>
              <Button variant={BUTTON_VARIANTS.INFO} size={BUTTON_SIZES.TINY}><span>{t('office.button.text')}</span><ArrowForwardIcon /></Button>
            </TextHolder>
            <ImageWrapper><Image src={t('office.image.src')} /></ImageWrapper>
          </Tile>
          <Tile $bg={t('ultra.backgroundImage.src')} $color={colors.secondary.ultra} to={t('ultra.button.link')}>
            <TextHolder>
            <Title dangerouslySetInnerHTML={{__html: parseMarkdown(t('ultra.title'))}} />
              <Description>{t('ultra.description')}</Description>
              <Button variant={BUTTON_VARIANTS.INFO} size={BUTTON_SIZES.TINY}><span>{t('ultra.button.text')}</span><ArrowForwardIcon /></Button>
            </TextHolder>
            <ImageWrapper><Image src={t('ultra.image.src')} /></ImageWrapper>
          </Tile>
          <Tile $bg={t('spa.backgroundImage.src')} $color={colors.secondary.spa} to={t('spa.button.link')}>
            <TextHolder>
            <Title dangerouslySetInnerHTML={{__html: parseMarkdown(t('spa.title'))}} />
              <Description>{t('spa.description')}</Description>
              <Button variant={BUTTON_VARIANTS.INFO} size={BUTTON_SIZES.TINY}><span>{t('spa.button.text')}</span><ArrowForwardIcon /></Button>
            </TextHolder>
            <ImageWrapper><Image src={t('spa.image.src')} /></ImageWrapper>
          </Tile>
          <Tile $bg={t('gaming.backgroundImage.src')} $color={colors.secondary.gaming} to={t('gaming.button.link')}>
            <TextHolder>
            <Title dangerouslySetInnerHTML={{__html: parseMarkdown(t('gaming.title'))}} />
              <Description>{t('gaming.description')}</Description>
              <Button variant={BUTTON_VARIANTS.INFO} size={BUTTON_SIZES.TINY}><span>{t('gaming.button.text')}</span><ArrowForwardIcon /></Button>
            </TextHolder>
            <ImageWrapper><Image src={t('gaming.image.src')} /></ImageWrapper>
          </Tile>
        </Mosaic>
      </Fold>
      <SimplifiedFooter />
    </Root>
  )
}

//export default withFirebase(DisambiguationHome)
export default DisambiguationHome